import { useState } from 'react';
import { Modal } from 'semantic-ui-react'; 
import { getAttachment, getSignedDocument } from 'http/esignatur';
import { triggerDownload } from 'http/file-storage';
import { downloadBase64 } from 'util/downloadBlob';
import { toast } from 'react-toastify';
import ColoredText from 'design/atoms/ColoredText';

const SignatureFlowFilesModal = ({ flow, trigger }) => {
    const [open, setOpen] = useState(false);
    const [documentBeingDownloaded, setDocumentBeingDownloaded] = useState(null);

    const handleDownloadDocument = async document => {
        if (documentBeingDownloaded) {
            return;
        }

        if (document.fileIDOfSignedVersion) {
            await triggerDownload(document.fileIDOfSignedVersion, 'attachment');
            return;
        }
            
        const downloadedDocument = await (
            document.approveInsteadOfSign
                ? getAttachment(flow.orderId, document.documentId)
                : getSignedDocument(flow.orderId, document.documentId)
        );

        downloadBase64('application/pdf', downloadedDocument.dataBase64, downloadedDocument.fileName);
    };

    const modal = open && (
        <Modal open onClose={() => setOpen(false)} size='small'>
            <Modal.Header>Underskrevne dokumenter</Modal.Header>
            <Modal.Content>
                {flow.documents.map(document => {
                    return (
                        <div>
                            <ColoredText
                                onClick={() => {
                                    setDocumentBeingDownloaded(document);
                                    handleDownloadDocument(document)
                                        .then(() => setDocumentBeingDownloaded(null))
                                        .catch(() => toast.error('Kunne ikke downloade dokumentet...'));
                                }}
                                link
                                content={document.name}
                                disabled={!!documentBeingDownloaded}
                                loading={documentBeingDownloaded === document}
                                underlined={false}
                                icon='download'
                                iconColor='green'
                                iconPosition='left'
                            />
                        </div>
                    );
                })}
            </Modal.Content>
        </Modal>
    );

    return (
        <>
            <span onClick={() => setOpen(true)}>{trigger}</span>
            {modal}
        </>
    );
};

export default SignatureFlowFilesModal;