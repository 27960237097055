import React, { useCallback, useEffect, useState } from 'react';
import { Icon, Loader, Popup } from 'semantic-ui-react';
import { isAkademi } from 'util/userMethods';
import { getStatusFromFact } from 'util/FactMapUtil';
import { isManualReviewNeeded } from 'util/ManualReview.js';
import { getFlowSummary } from 'http/esignatur';
import ColoredText from 'design/atoms/ColoredText';
import { statusesMap } from 'design/molecules/AppLauncher/userStatuses';
import FieldGroup from '../../../../components/FieldGroup';
import Signflow from '.';
import collectSystemDocuments from '../collectSystemDocuments';
import describeFlowStatus from './describeFlowStatus';
import RefetchFlowIcon from './RefetchFlowIcon';

const EsignaturSegment = ({ id, hasPayed, value, isTeaser, isRunning, isLocked, userData, values, runAction, flowContext, resetStateToBeforeSignatureFlow, reloadProductData }) => {
    const [flow, setFlow] = useState(null);
    const [loadingFlow, setLoadingFlow] = useState(true);

    const flowID = value?.esignaturSignFlow;
    const productStatus = getStatusFromFact(values.STATUS);

    useEffect(() => {
        if (!flowID) return setLoadingFlow(false);

        getFlowSummary(flowID)
            .then(flow => setFlow(flow))
            .finally(() => setLoadingFlow(false));
    }, [flowID, productStatus]);

    const refetchFlow = useCallback(async (options) => {
        if (!flowID) return;

        const flow = await getFlowSummary(flowID, options?.forceUpdate);

        setFlow(flow);

        return flow;
    }, [flowID]);

    useEffect(() => {
        if (flow?.status === 'Completed' && productStatus.step < statusesMap.done.step) {
            reloadProductData();
        }        
    }, [flow, reloadProductData, productStatus]);

    if (!hasPayed) {
        return null;
    }

    if (!value) {
        return (
            <span>
                Din årsrapport er ikke klar til at blive underskrevet
            </span>
        );
    }

    if (isTeaser) {
        return (
            <span>
                Indberetningsperioden er ikke startet endnu, og du kan derfor ikke sende din årsrapport til underskrift
            </span>
        );
    };

    const guards = [
        {
            acceptanceCondition: () => !isRunning,
        },
        {
            acceptanceCondition: () => !isLocked,
        },
        {
            acceptanceCondition: () => value && value.isReportable,
            errorMsg: `Kvalitetskontrollen indeholder uløste fejl`,
        },
        {
            acceptanceCondition: () => !isManualReviewNeeded(values),
            errorMsg: `Din kontoplan er ikke blevet godkendt endnu`,
        },
        {
            acceptanceCondition: () => !isAkademi(userData),
            errorMsg: `Akademi bruger er ikke tilladt at underskrive rapporten`,
        },
        {
            acceptanceCondition: () => !flowContext?.value?.forceWatermark,
            errorMsg: `Slet vandmærket før du sender til underskrift`,
        },
    ];

    const failedGuard = guards.find(guard => !guard.acceptanceCondition());

    const documents = collectSystemDocuments(userData, value, values);
    const flowStatus = describeFlowStatus(flow);
    const statusText = (
        <Popup
            basic
            position='top center'
            disabled={!flowStatus.flowDescription}
            content={flowStatus.flowDescription}
            trigger={(
                <strong>
                    Status:{' '}
                    <ColoredText
                        content={flowStatus.title} 
                        color={flowStatus.color}
                    />
                </strong>
            )}
        />
    );

    return (
        <FieldGroup
            unwrapped
            title={<span><Icon name='signup' /> Underskrift</span>}
            rightContent={(
                <span>
                    {statusText}
                    {productStatus.step === statusesMap.esignatur_flow.step && (
                        <RefetchFlowIcon
                            refetchFlow={refetchFlow}
                            size='small'
                        />
                    )}
                </span>
            )}
        >
            {loadingFlow && <Loader inline='centered' active />}
            {!loadingFlow && (
                <Signflow
                    documents={documents}
                    runAction={runAction}
                    flow={flow}
                    refetchFlow={refetchFlow}
                    productStatus={productStatus}
                    errorMessage={failedGuard?.errorMsg}
                    docgenID={id}
                    resetStateToBeforeSignatureFlow={resetStateToBeforeSignatureFlow}
                />
            )}
        </FieldGroup>
    );

};

export default EsignaturSegment;